import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import Link from "../link"
import Container from "../container"
import Spacer from "../spacer"
import Button from "../button"

import Row from "../grid/row"
import Col from "../grid/column"
import { CenteredH2Heading } from "../common/heading"

const Divider = styled.div`
  height: 1px;
  background: ${(props) => props.theme.colorPaleGrey};
`

const StyledCol = styled(Col)`
  text-align: center;
`

const ButtonContainer = styled.div`
  margin-top: ${rem(40)};
`

const Science = (props) => (
  <React.Fragment>
    <Container>
      <Spacer top bottom>
        <CenteredH2Heading dangerouslySetInnerHTML={{ __html: props.title }} />

        <Spacer top>
          <Row>
            <StyledCol smallUp={8} smallUpOffset={2}>
              <h4 className="styled-h4">{props.subtitle}</h4>
            </StyledCol>
          </Row>
        </Spacer>
      </Spacer>

      {props.blobs.map((item, index) => (
        <React.Fragment key={index}>
          <Row>
            <Col smallUp={12}>
              <Divider />
            </Col>
          </Row>

          <Spacer top bottom>
            <Row>
              <Col smallUp={4}>
                <div className="styled-cue">{item.title}</div>
              </Col>

              <Col smallUpOffset={2} smallUp={6}>
                <div className="styled-h4">{item.content}</div>

                <ButtonContainer>
                  <Link to={item.url}>
                    <Button>{item.buttonText}</Button>
                  </Link>
                </ButtonContainer>
              </Col>
            </Row>
          </Spacer>
        </React.Fragment>
      ))}
    </Container>
  </React.Fragment>
)

Science.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  blobs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      url: PropTypes.string,
      buttonText: PropTypes.string,
    })
  ).isRequired,
}

export default Science
