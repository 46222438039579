import React from "react"
import styled from "styled-components"
import { rem } from "polished"
// import Img from "gatsby-image"
import PropTypes from "prop-types"

import Container from "../container"
import Spacer from "../spacer"

import Row from "../grid/row"
import Col from "../grid/column"
import { GrayH2Heading } from "../common/heading"

const Wrapper = styled.div`
  background: ${(props) => props.theme.colorSand};
`

const TextContainer = styled.p.attrs({ className: `styled-h4` })`
  width: ${rem(525)};
  max-width: 90%;
  margin: 0 auto;
  margin-top: ${rem(27)};
  text-align: center;
`

const ColHeading = styled.div.attrs({ className: `styled-h3` })`
  text-align: center;
`

const Divider = styled.div`
  height: 1px;
  background: ${(props) => props.theme.colorPaleGrey};
`

const Practice = (props) => (
  <Wrapper>
    <Container>
      <Spacer top bottom>
        <GrayH2Heading dangerouslySetInnerHTML={{ __html: props.title }} />
      </Spacer>

      <Row>
        <Col smallUp={12}>
          <Divider />
        </Col>
      </Row>

      <Spacer top>
        <Row>
          {props.blobs.map((item, index) => (
            <Col smallUp={6} key={index}>
              <Spacer bottom>
                <ColHeading>0{index + 1}</ColHeading>

                <TextContainer
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              </Spacer>
            </Col>
          ))}
        </Row>
      </Spacer>
    </Container>
  </Wrapper>
)

Practice.propTypes = {
  title: PropTypes.string,
  blobs: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
    })
  ).isRequired,
}

export default Practice
