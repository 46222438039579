import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import Container from "../container"
import Spacer from "../spacer"
import PropTypes from "prop-types"

import Row from "../grid/row"
import Col from "../grid/column"

const Heading = styled.h1.attrs({ className: `styled-h2` })`
  text-align: center;
`

const StyledContainer = styled(Container)`
  border-bottom: 1px solid ${(props) => props.theme.colorBlack};
`

const StyledCol = styled(Col)`
  @media ${(props) => props.theme.smallDown} {
    padding-top: ${rem(20)};
  }
`

const IntroBlock = (props) => (
  <React.Fragment>
    <StyledContainer>
      <Spacer top bottom>
        <Heading dangerouslySetInnerHTML={{ __html: props.title }} />
      </Spacer>
      <Spacer bottom>
        <Row>
          <Col smallUp={6}>
            <h3
              className="styled-cue"
              dangerouslySetInnerHTML={{ __html: props.subheading }}
            />
          </Col>
          <StyledCol smallUp={6}>
            <p className="styled-h4">{props.content}</p>
          </StyledCol>
        </Row>
      </Spacer>
    </StyledContainer>
  </React.Fragment>
)

IntroBlock.propTypes = {
  title: PropTypes.string,
  subheading: PropTypes.string,
  content: PropTypes.string,
}

export default IntroBlock
