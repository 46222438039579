import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"
import colors from "../theme/sections/colors"
import { gatsbyImageObject } from "../config/sharp-image-proptypes"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const Line = styled.div`
  height: ${rem(40)};
  background: ${(props) => props.bg};
`

const Image = (props) => (
  <React.Fragment>
    <GatsbyImage
      backgroundColor={colors.colorSand}
      image={getImage(props.image)}
      alt={props.altText ?? ``}
    />

    {props.bg && <Line data-testid="Line" bg={props.bg} />}
  </React.Fragment>
)

Image.propTypes = {
  image: gatsbyImageObject,
  bg: PropTypes.any,
  altText: PropTypes.string,
}

export default Image
