import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import { StoreContext } from "../store"

import Meta from "../components/meta"
import Hero from "../components/hero"
import IntroBlock from "../components/page-providers/intro-block"
import ValueProps from "../components/page-providers/value-props"
import ImageWithLine from "../components/image-with-line"
import Practice from "../components/page-providers/practice"
import Science from "../components/page-providers/science"

import Icon1 from "../assets/images/temp/value-props-icon-1.svg"
import Icon2 from "../assets/images/temp/value-props-icon-2.svg"
import Icon3 from "../assets/images/temp/value-props-icon-3.svg"
import Icon4 from "../assets/images/temp/value-props-icon-4.svg"
import Icon5 from "../assets/images/temp/value-props-icon-5.svg"
import Icon6 from "../assets/images/temp/value-props-icon-6.svg"

class ProvidersPageContent extends React.Component {
  static contextType = StoreContext

  componentDidMount() {
    this.context.setHeaderTheme(`bright`)
  }

  render() {
    const content = {
      meta: {
        title: `Digital Treatments | For Providers`,
        description: `Check out how our evidence based and clinically validated software programs are designed to deliver safe and effective treatments to your patients.`,
        keywords: `Digital Intervention, ClickTx, Perscriptions, EHR, Personalized, Digital Therapeutics, Telehealth, mHealth`,
      },
      whatwedoBlock: {
        title: `Developing Software As Treatments`,
        description: `We are a regulated Software as a Medical Device (SaMD)-focused biotechnology company that develops our applications as medical treatments in accordance with all relevant FDA, ISO and IEC standards`,
      },
      introBlock: {
        title: `Clinically-Validated<br/> Digital Treatments <br/>for your Patients`,
        subheading: `Deliver effective<br/> treatments to<br/> your patients.`,
        content: `Our clinically validated software programs are designed to deliver effective treatments to your patients while offering the favorable safety profile inherent to a digital intervention.`,
      },
      valueProps: [
        {
          icon: Icon1,
          title: `Evidence Based`,
          content: `Our therapies are regulated products that have gone through clinical studies and regulatory review`,
        },
        {
          icon: Icon2,
          title: `Prescription Treatments`,
          content: `Our therapeutics are prescription products indicated for treatment and covered by most insurance`,
        },
        {
          icon: Icon3,
          title: `Easy to Use`,
          content: `Our applications require no special hardware beyond a smartphone, making them accessible to almost all patients`,
        },
        {
          icon: Icon4,
          title: `Highest Quality`,
          content: `We develop our software using the latest design techniques under rigorous quality and security programs`,
        },
        {
          icon: Icon5,
          title: `Fully Digital`,
          content: `Each therapeutic is fully digital, delivering treatment at the patient’s convenience - the only clinician is you`,
        },
        {
          icon: Icon6,
          title: `Cutting-edge Science`,
          content: `Each program is developed with the latest scientific insights and unique digital mechanisms of action`,
        },
      ],
      inPracticeBlock: {
        title: `Using <br/>Digital Therapeutics <br/>in your Practice`,
        blobs: [
          {
            content: `Digital therapeutics can be prescribed in your EHR or electronic prescribing system like any other product`,
          },
          {
            content: `The patient downloads the therapy from the app store and activates it with a pharmacist-issued activation code`,
          },
          {
            content: `The treatment period varies, but typically takes 8-12 weeks, during which the application engages the patient in an interactive, personalized treatment journey `,
          },
          {
            content: `The patient follows up with you during and after treatment to discuss results and next steps, same as after receiving a drug prescription`,
          },
        ],
      },
      scienceBlock: {
        title: `The Science Behind<br/> our Treatments`,
        subtitle: `Our therapeutics are developed in house by a team of doctorate-level translational and discovery scientists, working hand-in-hand with clinicians, patients and thought leaders in each therapeutic area. `,
        blobs: [
          {
            title: `Each product is developed by our team as a safe and effective therapy.`,
            content: `Our therapies are a unique blend of both well-validated therapeutic approaches, such as behavior change and cognitive remediation, and novel mechanisms of action, such as our proprietary cognitive-emotional rebalancing task.`,
            buttonText: `Learn more about our Products`,
            url: `/products`,
          },
          {
            title: `We evaluate each product for effectiveness and publish our research.`,
            content: `We conduct numerous clinical studies, including both early research pilots and phase III efficacy studies for regulatory registration. These results are then submitted for peer-review and publication.`,
            buttonText: `Learn more about our publications`,
            url: `/publications`,
          },
        ],
      },
    }

    return (
      <React.Fragment>
        <Meta {...content.meta} />

        <Hero image={this.props.data.providersImage1} />

        <IntroBlock {...content.introBlock} />

        <ValueProps valueProps={content.valueProps} />

        <ImageWithLine
          image={this.props.data.providersImage2}
          /* c8 ignore next */
          bg={(props) => props.theme.colorForest}
        />

        <Practice {...content.inPracticeBlock} />

        <Science {...content.scienceBlock} />

        <ImageWithLine image={this.props.data.providersImage3} />
      </React.Fragment>
    )
  }
}

ProvidersPageContent.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.any,
}

const ForProvidersPage = (props) => (
  <StaticQuery
    query={graphql`
      query {
        providersImage1: file(
          sourceInstanceName: { eq: "images" }
          relativePath: { eq: "temp/for-providers-hero.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              formats: AUTO
              placeholder: BLURRED
              breakpoints: [
                320
                480
                640
                768
                960
                1024
                1200
                1400
                1680
                1920
              ]
              backgroundColor: "transparent"
            )
          }
        }

        providersImage2: file(
          sourceInstanceName: { eq: "images" }
          relativePath: { eq: "temp/for-providers-image-2.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              formats: AUTO
              placeholder: BLURRED
              breakpoints: [
                320
                480
                640
                768
                960
                1024
                1200
                1400
                1680
                1920
              ]
              backgroundColor: "transparent"
            )
          }
        }

        providersImage3: file(
          sourceInstanceName: { eq: "images" }
          relativePath: { eq: "temp/for-providers-image-3.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              formats: AUTO
              placeholder: BLURRED
              breakpoints: [
                320
                480
                640
                768
                960
                1024
                1200
                1400
                1680
                1920
              ]
              backgroundColor: "transparent"
            )
          }
        }
      }
    `}
    render={(data) => <ProvidersPageContent data={data} {...props} />}
  />
)

export default ForProvidersPage
