import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import Container from "../container"
import Spacer from "../spacer"

import Row from "../grid/row"
import Col from "../grid/column"

const Heading = styled.h4.attrs({ className: `styled-cue` })`
  margin-bottom: ${rem(20)};
`

const Wrapper = styled.div`
  width: ${rem(503)};
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
  text-align: center;
  padding-bottom: ${rem(70)};
`

const StyledContainer = styled(Container)`
  padding-bottom: ${rem(30)};
`

const Icon = styled.img`
  width: ${rem(65)};
  margin: 0 auto;
  margin-bottom: ${rem(20)};
`

const ValueProps = (props) => (
  <React.Fragment>
    <StyledContainer>
      <Spacer top>
        <Row>
          {props.valueProps.map((item, index) => (
            <Col smallDown={12} smallUp={6} key={index}>
              <Wrapper>
                <Icon src={item.icon} />

                <Heading>{item.title}</Heading>
                <p className="styled-h4">{item.content}</p>
              </Wrapper>
            </Col>
          ))}
        </Row>
      </Spacer>
    </StyledContainer>
  </React.Fragment>
)

ValueProps.propTypes = {
  valueProps: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
    })
  ).isRequired,
}

export default ValueProps
